import 'react-slideshow-image/dist/styles.css';

import type { ImageProps } from 'next/legacy/image';
import Image from 'next/legacy/image';
import React from 'react';
import { Fade } from 'react-slideshow-image';

import defaultImages from '../../lib/default-images';
import styles from '../../styles/Banner.module.css';
import Container from './Container';

interface MyImageProps extends ImageProps {
  alt?: string;
}

type Props = {
  images: Array<MyImageProps>;
};

export default function Banner({ images }: Props) {
  return (
    <section className={styles.banner}>
      <Container className="slide-container">
        {images.length > 1 && (
          <Fade arrows={true} pauseOnHover={true}>
            {images.map((image, index) => (
              <Image
                key={index}
                alt={image.alt}
                src={image.src}
                height={image.height}
                width={image.width}
                layout="responsive"
                objectFit="cover"
                role="presentation"
                priority={index === 0}
                placeholder="blur"
                blurDataURL={defaultImages.default}
              />
            ))}
          </Fade>
        )}
        {images[0] && images.length === 1 && (
          <Image
            alt={images[0].alt}
            src={images[0].src}
            height={images[0].height}
            width={images[0].width}
            layout="responsive"
            role="presentation"
            priority={true}
            placeholder="blur"
            blurDataURL={defaultImages.default}
          />
        )}
      </Container>
    </section>
  );
}
