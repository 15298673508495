import Image from 'next/legacy/image';
import Link from 'next/link';

import bannerItem1 from '../../public/images/banner--meeting-rooms.png';
import bannerItem2 from '../../public/images/banner-waterloo-meeting-rooms.jpg';
import bannerItem3 from '../../public/images/london-monument-meeting-rooms-the-keel.jpg';
import bannerItem4 from '../../public/images/london-st-pauls-meeting-room-shakespeare-room.jpg';
import bannerItem5 from '../../public/images/meeting-room-liverpool-st.jpg';
import brochureImg from '../../public/images/osit-meeting-room-brochure.png';
import centralHeader from '../../public/images/osit-meetings-and-conferences-header.png';
import bannerItem6 from '../../public/images/safari-blackfriars-meeting-rooms.jpg';
import tileBlackfriars from '../../public/images/tile--london-blackfriars-meeting-rooms.png';
import tileLiverpoolSt from '../../public/images/tile--london-liverpoolst-meeting-rooms.png';
import tileMonument from '../../public/images/tile--london-monument-meeting-rooms.png';
import tileStPauls from '../../public/images/tile--london-stpauls-meeting-rooms.png';
import tileWaterloo from '../../public/images/tile--london-waterloo-meeting-rooms.png';
import ContactBlock from '../components/ContactBlock';
import GraphicBlackfriars from '../components/graphics/GraphicBlackfriars';
import GraphicLiverpoolSt from '../components/graphics/GraphicLiverpoolSt';
import GraphicMonument from '../components/graphics/GraphicMonument';
import GraphicStPauls from '../components/graphics/GraphicStPauls';
import GraphicWaterloo from '../components/graphics/GraphicWaterloo';
import Meta from '../components/Meta';
import Banner from '../components/ui/Banner';
import Card from '../components/ui/Card';
import Container from '../components/ui/Container';
import Download from '../components/ui/Download';
import LayoutMain from '../layouts/LayoutMain';
import styles from '../styles/MeetingRooms.module.css';

const MeetingRooms = () => (
  <>
    <Meta
      title="Meeting Rooms"
      description="UK and London meeting rooms for hire. Meeting rooms in London and throughout the UK hired for training, meetings and conferences for 2 to 100 people."
    />
    <Banner images={[
      {
        ...bannerItem1, width: 1536, height: 588, alt: 'Mad Hatter Meeting room'
      },
      {
        ...bannerItem2, width: 1536, height: 588, alt: 'London Waterloo Meeting room'
      },
      {
        ...bannerItem3, width: 1536, height: 588, alt: 'London Monument Keel Meeting room'
      },
      {
        ...bannerItem4, width: 1536, height: 588, alt: 'London St Pauls Shakespeare Meeting room'
      },
      {
        ...bannerItem5, width: 1536, height: 588, alt: 'Liverpool Street Meeting rooms'
      },
      {
        ...bannerItem6, width: 1536, height: 588, alt: 'Safari Meeting rooms'
      },
    ]} />

    <section className={styles.heading}>
      <Container size="xl">
        <div>
          <h1>Meeting Rooms available to hire</h1>
          <h2>
            Inspired meetings and conference spaces with wow interiors and
            great transport links.
          </h2>
        </div>
      </Container>
    </section>

    <section className={styles.main}>
      <Container size="xl">
        <div className={styles.content}>
          <Card
            image={{
              url: tileBlackfriars,
              alt: 'London Blackfriars Meeting Rooms',
            }}
            cta={{
              text: ' Click Here to Find Out More and Book',
              link: '/meeting-rooms/london-blackfriars/',
            }}
            hasBorder
          >
            <Link href="/meeting-rooms/london-blackfriars/">
              <div className={styles.badge}>
                <GraphicBlackfriars width={90} />
                <div>
                  <h4>Blackfriars</h4>
                  <h5>London EC4Y</h5>
                </div>
              </div>
            </Link>
            <p className="mx-6">
              The vibrant and fun style of our Blackfriars meeting rooms come
              with not just fantastic interiors that spark conversation, but
              impressive IT, Telecoms and ClickShare technology.
            </p>
          </Card>
          <Card
            image={{
              url: tileLiverpoolSt,
              alt: 'London Liverpool Street Meeting Rooms',
            }}
            cta={{
              text: ' Click Here to Find Out More and Book',
              link: '/meeting-rooms/london-liverpool-street/',
            }}
            hasBorder
          >
            <Link href="/meeting-rooms/london-liverpool-street/">
              <div className={styles.badge}>
                <GraphicLiverpoolSt width={90} />
                <div>
                  <h4>Liverpool Street</h4>
                  <h5>London EC2M</h5>
                </div>
              </div>
            </Link>
            <p className="mx-6">
              With their nod to the popular board game, Monopoly, we have a
              wide variety of meeting rooms available to hire at our Liverpool
              St Centre. Best-in-class interiors along with exceptional IT,
              Telecoms and ClickShare technology.
            </p>
          </Card>
          <Card
            image={{
              url: tileMonument,
              alt: 'London Monument Meeting Rooms',
            }}
            cta={{
              text: ' Click Here to Find Out More and Book',
              link: '/meeting-rooms/london-monument/',
            }}
            hasBorder
          >
            <Link href="/meeting-rooms/london-monument/">
              <div className={styles.badge}>
                <GraphicMonument width={90} />
                <div>
                  <h4>Monument</h4>
                  <h5>London EC3R</h5>
                </div>
              </div>
            </Link>
            <p className="mx-6">
              From small meeting rooms for short or more casual chats, larger
              meeting spaces for client conference calls, or board meeting and
              conference rooms - we&rsquo;ve got you covered with our variety
              of meeting rooms available to rent in St Paul&rsquo;s.
            </p>
          </Card>
          <Card
            image={{
              url: tileStPauls,
              alt: "London St Paul's Meeting Rooms",
            }}
            cta={{
              text: ' Click Here to Find Out More and Book',
              link: '/meeting-rooms/london-st-pauls/',
            }}
            hasBorder
          >
            <Link href="/meeting-rooms/london-st-pauls/">
              <div className={styles.badge}>
                <GraphicStPauls width={90} />
                <div>
                  <h4>St Paul&apos;s</h4>
                  <h5>London EC1A</h5>
                </div>
              </div>
            </Link>
            <p className="mx-6">
              The style and class of our meeting rooms at St Paul&rsquo;s come
              not only with fantastic interiors but with seamless IT, Telecoms
              and ClickShare technology as well.
            </p>
          </Card>

          <div className="relative">
            <Image
              src={centralHeader}
              alt="Office Space in Town Meetings & Conferences"
              layout="fill"
              objectFit="contain"
            />
          </div>

          <Card
            image={{
              url: tileWaterloo,
              alt: 'London Waterloo Meeting Rooms',
            }}
            cta={{
              text: ' Click Here to Find Out More and Book',
              link: '/meeting-rooms/london-waterloo/',
            }}
            hasBorder
          >
            <Link href="/meeting-rooms/london-waterloo/">
              <div className={styles.badge}>
                <GraphicWaterloo width={90} />
                <div>
                  <h4>Waterloo</h4>
                  <h5>London SE1</h5>
                </div>
              </div>
            </Link>
            <p className="mx-6">
              The magic and wonder of our Waterloo meeting rooms don&rsquo;t
              just wow with their fantastic interiors. The seamless IT,
              Telecoms and ClickShare technology are just as impressive.
            </p>
          </Card>
        </div>

        <div className={styles.download}>
          <Download
            img={brochureImg}
            title="Meeting Rooms"
            cta="Download brochure"
            url="https://assets.officespaceintown.com/f/107708/x/ebf6743251/osit-meeting-conference-brochure.pdf"
          />
        </div>
      </Container>
    </section>

    <section className={styles.intro}>
      <Container size="xl">
        <div className={styles.intro_txt}>
          <p>
            Serviced offices are in our DNA but we also have a huge passion
            for inspiring and productive meeting rooms. With the number of
            meetings people have in any given week, it&rsquo;s important we
            are comfortable when having them right?!
          </p>
          <p>
            Across London, Edinburgh, Cardiff and Northampton, we have a wide
            range of meeting rooms available to hire. From casual spaces for
            informal catch-ups over coffee to larger team meeting spaces and
            client event venues - we&rsquo;ve got you covered.
          </p>
          <p>
            All located near principal transport hubs with easy access to
            mainline stations and underground routes, our meeting rooms
            aren&rsquo;t just beautifully designed, they are also easy to get
            to.
          </p>
          <p>
            Whether you are looking for a traditional and elegant
            boardroom-style space, a conference or training room for up to 100
            delegates, or a more intimate meeting room to hold interviews,
            we&rsquo;ve got a space that will suit you.
          </p>
          <ul>
            <li>Fully air-conditioned with high-speed internet access and</li>
            <li>
              Equipped with flat screen HD TV and conferencing facilities
            </li>
            <li>Available to hire by the day, half-day or by the hour.</li>
            <li>
              A comprehensive range of catering services including breakfast
              and a choice of lunch menus
            </li>
            <li>
              Professional business support team on hand to ensure that your
              meeting runs smoothly
            </li>
          </ul>
          <p>
            All of our <Link href="https://www.officespaceintown.com/serviced-offices/">office centres</Link>{' '}
            also have podcast equipment which can be set up in some of our
            smaller meeting rooms if you are looking for a space to record
            podcast content.
          </p>
          <p>
            Ultimately, if you are on the hunt for quality spaces with quality
            interiors, IT kit, and service, you&rsquo;ve come to the right
            place.
          </p>
        </div>
      </Container>
    </section>

    <ContactBlock />
  </>
);

MeetingRooms.Layout = LayoutMain;
export default MeetingRooms;
