import cn from 'classnames';
import type { StaticImageData } from 'next/image';
import Image from 'next/legacy/image';
import Link from 'next/link';
import type { ReactNode } from 'react';

import styles from '../../styles/Card.module.css';
import Btn from './Button';

interface Img {
  url: StaticImageData;
  alt?: string;
}

interface Cta {
  text: string;
  link: string;
  color?: 'primary' | 'secondary';
}

type Props = {
  children?: ReactNode;
  image?: Img;
  title?: string;
  body?: string;
  cta?: Cta;
  hasShadow?: boolean;
  hasBorder?: boolean;
};

export default function Card({
  children,
  image,
  title,
  body,
  cta,
  hasShadow = false,
  hasBorder = false,
}: Props) {
  return (
    <div
      className={cn(
        styles.card,
        hasShadow ? styles.shadow : undefined,
        hasBorder ? styles.bord : undefined
      )}
    >
      {image && (
        <>
          {cta?.link ? (
            <Link href={cta.link} className="block w-full">
              <Image
                src={image.url}
                alt={image.alt}
                layout="responsive"
                placeholder="blur"
              />
            </Link>
          ) : (
            <Image
              src={image.url}
              alt={image.alt}
              layout="responsive"
              placeholder="blur"
            />
          )}
        </>
      )}

      {title && (
        <h2 className={styles.title}>
          {cta?.link ? <Link href={cta.link}>{title}</Link> : title}
        </h2>
      )}

      {body && <div className={styles.body}>{body}</div>}

      {children}

      {cta && (
        <Btn
          href={cta.link}
          color={cta.color ? cta.color : 'primary'}
          className={styles.cta}
        >
          {cta.text}
        </Btn>
      )}
    </div>
  );
}
